<template>
    <el-dialog title="登 录" :visible="true" width="600px" :close-on-press-escape="false" :close-on-click-modal="false" :show-close="false">
        <el-form>
            <el-form-item label="手机号码">
                <el-input v-model="loginForm.mobile"/>
            </el-form-item>
            <el-form-item label="密码">
                <el-input v-model="loginForm.password" type="password"/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="handleLogin">登 录</el-button>
                  </span>
    </el-dialog>
</template>

<script>
    export default {
        name: "login",
        data() {
            return {
                visible: false,
                loading: false,
                loginForm: {
                    mobile: "",
                    password: "",
                }
            }
        },
        methods: {
            showLogin() {
                this.visible = true;
            },
            handleLogin() {
                this.loading = true
                this.$store.dispatch('auth/login', this.loginForm).then(() => {
                    this.loading = false;
                    location.reload()
                }).catch(e => {
                    this.loading = false;
                })
            }
        }
    }
</script>

<style scoped>

</style>
