<template>
    <div style="margin:50px">
        <template v-if="user.id">
            <el-card style="margin-bottom: 20px">
                当前socket链接地址：{{$socket.io.uri}}
            </el-card>
            <el-card style="margin-bottom: 20px">
                <el-row>
                    <el-col :span="12">
                        当前房间号：{{roomNo ? roomNo :'未加入房间'}}
                    </el-col>
                    <el-col :span="12" style="text-align: right">
                        当前用户：
                        <el-dropdown @command="handleUserCommand">
  <span class="el-dropdown-link">
    {{user.nickname}} [{{user.id}}]
      <i class="el-icon-arrow-down el-icon--right"></i>
  </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="logout">切换用户</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </el-col>
                </el-row>
            </el-card>
            <el-card style="margin-bottom: 50px">
                <el-button-group>
                    <el-button v-if="!connected" @click="connect" type="primary">连接</el-button>
                    <el-button v-if="connected" @click="disconnect" type="danger">断开连接</el-button>

                    <el-button v-for="(item,index) in emitList" :key="index" @click="handleEmit(item)" type="primary"
                               :disabled="disabled(item)">
                        {{item.text}}
                    </el-button>
                </el-button-group>
            </el-card>
            <el-row :gutter="20">
                <el-col :span="12">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span>Emit列表</span>
                            <el-button style="float: right; padding: 3px 0" type="text" @click="emits = []">清空
                            </el-button>
                        </div>
                        <el-card v-for="(item,index) in emits" :key="index" style="margin-bottom: 20px">
                            <div slot="header">
                                {{item.event}}
                            </div>
                            <vue-json-pretty :deep="2" :data="item.data"></vue-json-pretty>
                        </el-card>
                    </el-card>
                </el-col>
                <el-col :span="12">
                    <el-card>
                        <div slot="header" class="clearfix">
                            <span>消息列表</span>
                            <el-button style="float: right; padding: 3px 0" type="text" @click="messages = []">清空
                            </el-button>
                        </div>
                        <el-card v-for="(item,index) in messages" :key="index" style="margin-bottom: 20px">
                            <div slot="header">
                                {{item.event}}
                            </div>
                            <vue-json-pretty :deep="2" :data="item.data"></vue-json-pretty>
                        </el-card>
                    </el-card>
                </el-col>
            </el-row>

            <el-dialog :visible.sync="showDialog" :title="dialogEmit ? dialogEmit.text : null" width="600px" :close-on-press-escape="false" :close-on-click-modal="false">
                <el-form v-if="dialogEmit" @submit.native.prevent>
                    <el-form-item v-for="(item,index) in dialogEmit.params" :key="index" :label="item.text">
                        <el-input v-model="item['value']" @keyup.enter.native="handleDialogEmit"/>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="handleCloseDialog">取 消</el-button>
                    <el-button type="primary" @click="handleDialogEmit">确 定</el-button>
                  </span>
            </el-dialog>
        </template>
        <login v-else/>

    </div>
</template>

<script>
    import VueJsonPretty from 'vue-json-pretty'
    import 'vue-json-pretty/lib/styles.css'

    const clone = require('clone')
    import login from "@/components/login";
    import {mapGetters} from 'vuex'

    export default {
        components: {
            VueJsonPretty,
            login
        },
        computed: {
            ...mapGetters([
                'user'
            ])
        },
        data() {
            return {
                connected: false,
                eventList: ['connect', 'disconnect', 'error', 'receiveAnnouncement',
                    'welcome', 'updateRoom', 'receiveGift', 'updateUser', 'receiveGlobalGift', 'receiveMessage',
                    'pkRequest','pkReject','pkAgree','pkStart','pkUpdateProgress','pkEnd','pkClose'],
                emitList: [
                    {
                        event: 'joinRoom',
                        text: '加入房间',
                        params: [
                            {
                                name: "room_no",
                                text: "房间号",
                                value: "10000002"
                            }
                        ],
                    },
                    {
                        event: 'presentGift',
                        text: '赠送礼物',
                        params: [
                            {
                                name: "gift_id",
                                text: "礼物ID",
                                value: "23"
                            },
                            {
                                name: "num",
                                text: "数量",
                                value: "1"
                            }
                        ],
                    },
                    {
                        event: 'sendMessage',
                        text: '发送消息',
                        params: [
                            {
                                name: "content",
                                text: "内容",
                                value: ""
                            }
                        ],
                    }
                ],
                roomNo: null,
                messages: [],
                emits: [],
                showDialog: false,
                dialogEmit: null,
            }
        },
        created() {
            this.eventList.forEach((event) => {
                this.sockets.subscribe(event, data => this.addMessage(event, data))
            })
        },
        sockets: {
            connect() {
                this.connected = true
                if(this.roomNo){
                    this.$socket.emit('joinRoom', {room_no:this.roomNo});
                    this.addEmit('joinRoom', {room_no:this.roomNo});
                }
            },
            disconnect() {
                this.connected = false
                // this.roomNo = null
            }
        },
        methods: {
            connect() {
                this.$socket.connect()
            },
            disconnect() {
                this.$socket.close();
            },
            disabled(item) {
                if (!this.connected) {
                    return true;
                } else {
                    if (item.event === 'joinRoom') {
                        return false;
                    }
                    return this.roomNo === null
                }
            },
            handleEmit(item) {
                if (item.params && item.params.length > 0) {
                    this.dialogEmit = clone(item);
                    this.showDialog = true;
                } else {
                    let params = {}
                    if (this.roomNo) {
                        params['room_no'] = this.roomNo;
                    }
                    this.$socket.emit(item.name, params);
                    this.addEmit(item.name, params);
                }
            },
            handleDialogEmit() {
                let params = {};
                this.dialogEmit.params.forEach(function (item) {
                    params[item.name] = item['value'];
                })
                if (this.dialogEmit.event !== 'joinRoom' && this.roomNo) {
                    params['room_no'] = this.roomNo;
                }
                this.$socket.emit(this.dialogEmit.event, params);
                this.addEmit(this.dialogEmit.event, params);
                this.handleCloseDialog();
            },
            handleCloseDialog() {
                this.dialogEmit = null;
                this.showDialog = false;
            },
            addMessage(event, data) {
                this.messages = [{
                    event: event,
                    data: data,
                }, ...this.messages]
            },
            addEmit(event, data) {
                if (event === 'joinRoom') {
                    this.roomNo = data.room_no
                }
                this.emits = [{
                    event: event,
                    data: data,
                }, ...this.emits]
            },
            handleUserCommand(command) {
                if (command === 'logout') {
                    this.$store.dispatch('auth/logout').then(() => {
                        location.reload()
                    });
                }
            }
        },
    }
</script>

<style>
    *{
        font-size: 14px;
    }
    .el-dropdown-link {
        cursor: pointer;
        color: #409EFF;
    }

    .el-icon-arrow-down {
        font-size: 12px;
    }
</style>
